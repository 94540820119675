import CustomerLogo from '../CustomerLogo';

const LogoMoz = () => {
  return (
    <CustomerLogo
      fill="currentColor"
      role="img"
      viewBox="0 0 108 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Moz</title>
      <desc>Moz logo</desc>
      <path
        d="M91.73 28.954l15.826-17.516H74.968c-.305.01-.595.142-.812.368-.217.226-.344.53-.356.85v3.526C70.752 11.018 64.807 7.5 57.935 7.5c-8.962 0-16.394 6.004-17.779 13.855v-9.917h-5.672a4.611 4.611 0 00-1.865.446 4.815 4.815 0 00-1.552 1.175L20.082 25.88 9.097 13.059a4.815 4.815 0 00-1.551-1.175 4.612 4.612 0 00-1.865-.446H0V37.04h6.625c.309-.018.6-.156.817-.387.218-.232.345-.54.359-.864V23.782L20.082 38.14l12.282-14.36V35.79c.011.324.137.632.353.863.216.231.507.37.815.389h6.625V26.645c1.384 7.851 8.8 13.855 17.778 13.855 9.945 0 18.001-7.39 18.001-16.5.002-1.52-.225-3.03-.672-4.476h14.402L73.84 37.041h32.572c.305-.013.594-.145.81-.37a1.31 1.31 0 00.358-.848v-6.869H91.73zm-33.796 3.241c-4.936 0-8.944-3.669-8.944-8.195s4-8.195 8.944-8.195c4.945 0 8.945 3.669 8.945 8.195s-4 8.195-8.945 8.195z"
        fill="currentColor"
        fillOpacity="0.8"
      />
    </CustomerLogo>
  );
};

export default LogoMoz;

'use client';

import { styled } from 'styled-components';
import { WistiaPlayer } from '@wistia/wistia-player-react';

type PlayerProps = {
  /** 10 character hash ID of the media */
  hashedId: string;

  /** Optional class name, nesc. for extending as a styled-component */
  className?: string;
};

const Player = styled.div`
  margin: ${({ theme }) => theme.spacing[3]} auto;
  overflow: hidden;
  width: 100%;
`;

const Video = ({ hashedId, className }: PlayerProps) => {
  return (
    <Player className={className}>
      <WistiaPlayer mediaId={hashedId} />
    </Player>
  );
};

export default Video;

'use client';

import { styled } from 'styled-components';
import Logo360Learning from './logos/360Learning';
import LogoMoz from './logos/Moz';
import LogoBamboo from './logos/Bamboo';
import LogoCasper from './logos/Casper';
import LogoTerminus from './logos/Terminus';
import LogoUnbounce from './logos/Unbounce';
import LogoZendesk from './logos/Zendesk';
import LogoContainerStore from './logos/ContainerStore';

export type CustomerLogoProps = {
  brands?: (
    | '360Learning'
    | 'Bamboo'
    | 'Casper'
    | 'ContainerStore'
    | 'Moz'
    | 'Terminus'
    | 'Unbounce'
    | 'Zendesk'
  )[];

  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
};

export const brandLogos = {
  '360Learning': Logo360Learning,
  Bamboo: LogoBamboo,
  Casper: LogoCasper,
  ContainerStore: LogoContainerStore,
  Moz: LogoMoz,
  Terminus: LogoTerminus,
  Unbounce: LogoUnbounce,
  Zendesk: LogoZendesk,
};

const LogoWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing[1]};
  margin: auto;
  padding-bottom: ${({ theme }) => theme.spacing['1']};
  width: 100%;

  .logoList:hover .logoGroup {
    animation-play-state: paused;
  }
`;

const LogoList = styled.div.attrs({
  className: 'logoList',
})`
  display: flex;
  gap: ${({ theme }) => theme.spacing[3.75]};
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0deg 0% 0% / 0%),
    hsl(0deg 0% 0% / 100%) 20%,
    hsl(0deg 0% 0% / 100%) 80%,
    hsl(0deg 0% 0% / 0%)
  );
  overflow: hidden;
  user-select: none;

  @media (prefers-reduced-motion) {
    & div:nth-child(2) {
      /**  Hide the second set of logos when reduced motion is enabled. */
      display: none;
    }
  }
`;

const LogoGroup = styled.div.attrs({
  className: 'logoGroup',
})<{
  $brands: CustomerLogoProps['brands'];
}>`
  align-items: center;
  color: inherit;

  /* Speed up the animation time when we aren't rendering the full set of logos. */
  animation: scroll-logos
    ${({ $brands }) =>
      $brands.length < Object.values(brandLogos).length ? '15s' : '30s'}
    linear infinite;
  display: flex;
  flex-shrink: 0;
  gap: ${({ theme }) => theme.spacing[3.75]};
  justify-content: space-around;
  min-width: 100%;

  @media (prefers-reduced-motion) {
    animation: none;
    flex-wrap: wrap;
    width: fit-content;
    justify-content: flex-start;
  }

  @keyframes scroll-logos {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }
`;

const CustomerLogos = ({
  brands = [
    '360Learning',
    'Bamboo',
    'Casper',
    'ContainerStore',
    'Moz',
    'Terminus',
    'Unbounce',
    'Zendesk',
  ],
  className,
}: CustomerLogoProps) => {
  const brandItems = brands.map((brand) => {
    const Logo = brandLogos[brand];
    return <Logo key={brand} />;
  });

  return (
    <LogoWrapper className={className}>
      <LogoList>
        <LogoGroup $brands={brands}>{brandItems}</LogoGroup>
        <LogoGroup $brands={brands} aria-hidden="true">
          {brandItems}
        </LogoGroup>
      </LogoList>
    </LogoWrapper>
  );
};

export default CustomerLogos;

import CustomerLogo from '../CustomerLogo';

const LogoCasper = () => {
  return (
    <CustomerLogo
      fill="currentColor"
      role="img"
      viewBox="0 0 120 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Casper logo</title>
      <path
        d="M37.449 18.654c-5.752 0-10.106 3.684-10.106 8.942 0 5.259 4.374 8.943 10.106 8.943 2.756 0 4.873-1.127 5.732-2.02v1.788h4.494V18.868H43.18v1.789c-.859-.894-3.116-2.003-5.732-2.003zm.12 14.2c-3.376 0-5.872-2.342-5.872-5.258 0-2.915 2.616-5.258 5.872-5.258 3.255 0 5.732 2.343 5.732 5.258 0 2.916-2.477 5.259-5.732 5.259zm38.567-14.2c-2.617 0-4.614.894-5.872 2.02v-1.788H65.91V45.5l4.374-.447V34.429c1.258 1.234 3.375 2.02 5.872 2.02 5.992 0 9.986-3.469 9.986-8.942-.02-5.383-3.994-8.853-10.006-8.853zm-.12 14.2c-3.495 0-5.732-2.235-5.732-5.258 0-3.022 2.257-5.258 5.732-5.258 3.495 0 5.872 2.236 5.872 5.258-.12 3.023-2.377 5.259-5.872 5.259zm43.82-14.2c-2.736 0-4.254.894-5.113 2.02v-1.788h-4.374v17.439h4.374v-9.623c0-2.575 1.998-4.364 4.754-4.364h.379c-.02 0-.02-3.684-.02-3.684zm-21.97 0c-5.732 0-10.226 4.024-10.226 8.942 0 4.919 4.494 8.943 10.226 8.943 4.754 0 8.609-2.343 9.867-5.473h-5.253c-.619 1.127-2.257 1.896-4.614 1.896-2.876 0-5.113-1.788-5.612-4.024h15.599c.119-.447.119-.894.119-1.341 0-4.919-4.493-8.943-10.106-8.943zm-5.372 6.814c.619-1.788 2.756-3.237 5.372-3.237 2.497 0 4.754 1.449 5.373 3.237H92.494zm-35.452-3.344c-.879 0-1.877.34-1.877 1.341 0 1.127 1.258 1.449 3.615 2.236 3.235 1.127 4.873 2.468 4.873 5.15 0 2.79-2.616 5.706-7.11 5.706s-6.87-3.023-6.99-5.473h4.254c.26 1.127 1.258 2.021 2.756 2.021 1.617 0 2.616-1.001 2.616-2.128 0-.787-.38-1.681-3.116-2.343-2.995-.68-5.113-2.128-5.113-5.026 0-2.683 2.617-4.811 5.992-4.811 2.996 0 4.873 1.896 5.732 3.13l-3.615 1.449c-.519-.93-1.258-1.252-2.017-1.252z"
        fill="currentColor"
        fillOpacity="0.8"
      />
      <path
        d="M14.1 11.5C6.113 11.5 0 17.08 0 24.02c0 6.94 6.112 12.52 14.1 12.52 5.114 0 9.867-2.469 11.984-5.92l-4.114-2.021c-1.618 2.575-4.374 4.024-7.87 4.024-2.496 0-4.992-.894-6.61-2.468-1.758-1.682-2.736-3.81-2.736-6.046 0-2.235.998-4.364 2.736-6.045 1.618-1.574 3.994-2.468 6.61-2.468 2.757 0 4.375.787 5.493 1.574 1.259.787 2.497 1.574 4.614 1.574 3.875 0 5.612-2.79 5.992-4.257l-4.374-.787c0 .107-.38 1.234-1.618 1.234-.62 0-.999-.232-1.878-.787-1.597-1.306-3.734-2.647-8.228-2.647z"
        fill="currentColor"
        fillOpacity="0.8"
      />
    </CustomerLogo>
  );
};

export default LogoCasper;

import { styled } from 'styled-components';

const CustomerLogo = styled.svg`
  height: 30px;

  ${({ theme }) => theme.mq.minWidth.medium} {
    height: 48px;
  }
`;

export default CustomerLogo;

import CustomerLogo from '../CustomerLogo';

const LogoZendesk = () => {
  return (
    <CustomerLogo
      fill="currentColor"
      role="img"
      viewBox="0 0 157 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Zendesk logo</title>
      <path
        d="M104.21 34.911a7.845 7.845 0 005.588-2.172l2.773 2.985c-1.832 1.882-4.364 3.276-8.312 3.276-6.762 0-11.127-4.485-11.127-10.554a10.35 10.35 0 0110.144-10.554h.375c6.713 0 10.519 5.107 10.186 12.195H97.95c.566 2.942 2.631 4.817 6.246 4.817l.014.007zm4.824-8.227c-.424-2.744-2.065-4.768-5.355-4.768a5.455 5.455 0 00-5.73 4.768h11.085zM0 34.692l12.216-12.407H.29v-3.891h17.585v3.976L5.66 34.77h12.379v3.897H0v-3.975zM31.754 34.911a7.845 7.845 0 005.588-2.172l2.773 2.985C38.283 37.606 35.751 39 31.803 39c-6.762 0-11.127-4.485-11.127-10.554A10.347 10.347 0 0130.82 17.892h.375c6.713 0 10.519 5.107 10.186 12.195H25.508c.566 2.942 2.631 4.817 6.246 4.817v.007zm4.838-8.227c-.424-2.744-2.065-4.768-5.354-4.768a5.454 5.454 0 00-5.73 4.768h11.084zM67.745 28.467c0-6.487 4.845-10.54 10.179-10.54a8.772 8.772 0 016.755 3.042V9h4.315v29.596H84.68v-2.829a8.672 8.672 0 01-6.797 3.204 10.22 10.22 0 01-10.144-10.299v-.24l.007.035zm17.175-.078a6.508 6.508 0 100 .205v-.205zM116.631 34.303l3.905-2.03a5.805 5.805 0 005.213 2.957c2.441 0 3.707-1.252 3.707-2.674 0-1.62-2.363-1.988-4.952-2.511-3.459-.708-7.073-1.868-7.073-6.041 0-3.205 3.048-6.162 7.859-6.126 3.784 0 6.592 1.5 8.184 3.933l-3.622 1.988a5.263 5.263 0 00-4.562-2.314c-2.321 0-3.537 1.14-3.537 2.434 0 1.457 1.874 1.867 4.803 2.51 3.339.708 7.123 1.826 7.123 6.042 0 2.83-2.44 6.571-8.227 6.529-4.244 0-7.208-1.705-8.913-4.704M145.492 29.599l-3.423 3.735v5.27h-4.315V9h4.315v19.382l9.16-10.052h5.256l-7.944 8.68 8.149 11.593h-4.888l-6.31-9.004zM54.694 17.92c-5.129 0-9.422 3.325-9.422 8.842v11.834h4.4V27.28c0-3.325 1.91-5.313 5.17-5.313 3.261 0 4.888 1.988 4.888 5.313v11.317h4.4V26.762c0-5.517-4.293-8.842-9.422-8.842"
        fill="currentColor"
        fillOpacity="0.8"
      />
    </CustomerLogo>
  );
};

export default LogoZendesk;

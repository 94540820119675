'use client';

import { styled } from 'styled-components';
import Image from 'next/image';

// TODO Refactor so that we only need to pass in the asset_id instead of the
// full URL.
//
// Making this better for a) contentful spaces config changes b) asset changes.
//
// NOTE: for b) each revision of an asset changes the {unique_id}, and we'd
// need to make API calls to get the FQR URL at build/compile time.
type ImageProps = {
  /**
   * absolute-URL of the asset
   */
  src: string;
  /**
   * Alt text for image
   */
  alt: string;
  /**
   *  Height of image
   */
  height: number;
  /**
   *  Width of image
   */
  width: number;
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
};

const StyledImage = styled(Image)`
  border-radius: ${({ theme }) => theme.radius.std};
  height: auto;
  width: 100%;
`;

/**
 * TODO: if desired, we could move this to a loaderFile configuration in
 * next.config.js to configure every instance of next/image in your
 * application, without passing a prop.
 * https://nextjs.org/docs/app/api-reference/components/image#loader
 *
 * Params:
 *
 * - Format: fm; jpg | png | webp | gif | avif
 * - Width & Height: w & h
 * - Fit: scale by default, also fill, crop, pad, etc.
 * - Quality: q (1-100)
 *
 * cref. https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/change-the-resizing-behavior
 */
const contentfulLoader = ({ src, width, quality }) => {
  const url = new URL(src);
  url.searchParams.set('fm', 'avif');
  url.searchParams.set('fit', 'fill');
  url.searchParams.set('w', width.toString());
  url.searchParams.set('q', (quality || 70).toString());
  return url.href;
};

/**
 * Abstraction for Next/Image, which takes a Renovation space Contentful
 * asset and renders a stylized, optimized asset.
 */
const ContentfulImage = ({
  src,
  alt,
  width,
  height,
  className,
}: ImageProps) => {
  return (
    <StyledImage
      alt={alt}
      className={className}
      height={height}
      loader={contentfulLoader}
      src={src}
      width={width}
    />
  );
};

export default ContentfulImage;

'use client';

import Link from 'next/link';
import cs from 'classnames';
import { styled } from 'styled-components';

type TextLinkVariant = 'anchor' | 'link';

type TextLinkProps = {
  /**
   * Style variant to display as a navigation link (change pages), or an anchor
   * (same page)
   */
  variant?: TextLinkVariant;
  /**
   * What href should this point to?
   */
  href: string;
  /**
   * What does the link say?
   */
  children: string;
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
  /**
   * Optional click event handler, using the same signature as
   * next/link
   */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

// See https://unicode-explorer.com/ for more
const arrowContentMap = {
  anchor: '↓',
  link: '→',
};

const TextLinkWrapper = styled(Link)<{
  $variant: TextLinkVariant;
}>`
  color: var(--global-link-color);
  text-decoration: underline transparent;
  transition: text-decoration-color 333ms ease-in-out;

  &::after {
    content: ${({ $variant }) => `'${arrowContentMap[$variant]}'`};
    display: inline-block;
    padding-inline-start: ${({ theme }) => theme.spacing['0.25']};
    transition: transform 250ms ease-in-out;
  }

  &:hover,
  &:active {
    color: var(--global-hover-color);
    text-decoration: underline currentcolor;

    &::after {
      transition: transform 250ms ease-in-out;

      ${({ theme, $variant }) =>
        $variant === 'link' &&
        `transform: translateX(${theme.spacing['0.25']});`}
      ${({ theme, $variant }) =>
        $variant === 'anchor' &&
        `transform: translateY(${theme.spacing['0.25']});`}
    }
  }
`;

const TextLink = ({
  href,
  children,
  className,
  onClick,
  variant = 'link',
}: TextLinkProps) => (
  <TextLinkWrapper
    $variant={variant}
    className={cs(className)}
    href={href}
    onClick={onClick}
  >
    {`${children}`}
  </TextLinkWrapper>
);

export default TextLink;
